<template>
  <div>
    <Loading v-if="isloading" dialog="isloading" :title="isUpate?'جاري تعديل المجموعة':'جاري انشاء المجموعة'"

    />

      <v-dialog
          width="60%"
          persistent
          v-model="isDialog">

        <v-card elevation="0">
          <v-card-title>

            <span class="text-h5" v-if="!isUpate">انشاء مجموعة صلاحيات جديد</span>
            <span class="text-h5" v-if="isUpate">تعديل مجموعة الصلاحيات </span>
          </v-card-title>
          <v-card-text>

            <v-col>
              <v-card ref="form" elevation="0">
                <v-card-text>
                  <v-form v-model="isFormValid">

                    <v-text-field

                        ref="inputRef"
                        outlined
                        dense
                        type="text"
                        color="rgb(200, 200, 200)"
                        placeholder="اسم مجموعة الصلاحيات"
                        v-model="role.name"
                        :rules="[() => !!role.name || 'يجب ادخال اسم المجموعة']"

                        class="input-group--focused"
                    ></v-text-field>
                    <v-text-field

                        ref="inputRef"
                        outlined
                        dense
                        type="textarea"
                        color="rgb(200, 200, 200)"
                        :autosize="{ minRows: 2, maxRows: 4}"
                        placeholder="وصف المجموعة"
                        v-model="role.description"
                        :rules="[() => !!role.description || 'يجب ادخال وصف المجموعة']"

                        class="input-group--focused"
                    ></v-text-field>

                    <v-treeview
                        ref="inputRef"
                        :items="routes"
                        :dense="dense"
                        :selectable="selectable"
                        :hoverable="hoverable"
                        :open-on-click="openOnClick"
                        :selected-color="selectedColor"
                        :color="color"
                        :shaped="shaped"
                        :rounded="rounded"
                        v-model="selectedPermission"
                    ></v-treeview>




                  </v-form>


                </v-card-text>
              </v-card>

              <v-row>

                <v-btn class="ma-2"
                       width="20%"
                       outlined
                       color="paink"

                       @click="close"
                >
                  الغاء
                </v-btn>

                <v-btn class="ma-2"
                       width="20%"
                       outlined
                       color="green"
                       :disabled="!isFormValid"
                       @click="create()"
                >
                  {{isUpate?'تعديل':'انشاء'}}
                </v-btn>

              </v-row>
            </v-col>

          </v-card-text>


        </v-card>
        <v-alert
            class="mt-5"
            left
            border="left"
            color="red"
            dismissible
            elevation="9"
            type="error"
            v-model="isShow"
        >{{msgError}}</v-alert>
        <SuccessDialog :title="msgSucess"  v-if="successDialog" :success-dialog.sync="successDialog" />
      </v-dialog>




  </div>

</template>

<script>

import Loading from '@/components/LoadingDialog.vue';
import PushServicesSocket from '@/services/PushServicesSocket.js';
import SuccessDialog from '@/components/dialogs/SucessSended.vue';

const defaultRole = {
  name: '',
  description: '',
  routes: []
}
export default {
  components: {
    Loading,
    SuccessDialog

  },
  props:{
    routes:[],
    isUpate: Boolean,
    contentItem:{},
    isDialog: {
      default: false
    },
  },

  data () {
    return {
      dense: false,
      selectable: true,

      hoverable: true,
      openOnClick: true,
      selectedPermission:[],

      shaped: true,
      rounded: true,
      color: 'primary',
      selectedColor: 'accent',
      isFormValid: false,
      role: Object.assign({}, defaultRole),
      isloading: false,
      checkStrictly: false,
      defaultProps: {
        children: 'children',
        label: 'name'

      },
      nameModal:"",
      destination:"",
      isShow: false,
      successDialog: false,
      msgSucess:this.isUpate?"تم التعديل بنجاح":"تم انشاء مجموعة الصلاحيات بنجاح",
      msgError:"فشل ",

    }
  },

  methods: {
    close() {
      this.$emit('update:isDialog', false);
    },
    async getMyPermissions(userID){
      this.isloading = true;
      let _temps;
      _temps = await PushServicesSocket.GetData("/authgroup/get/"+userID);
      if(_temps.status)
        _temps=_temps.data.map(e=>parseInt(e.permission_id));

      this.routes.forEach((values) =>{
            values.children.forEach((child) =>{
              if(_temps.find(value=>child.permission_id==value)>-1)
              this.selectedPermission.push(child.id);
            });
      });
      this.isloading = false;
    },
    async initializeUpdate(){

      await this.getMyPermissions(this.contentItem.id);
      this.role.name = this.contentItem.name;
      this.role.description = this.contentItem.description;
    },
    async create() {

      try {

        this.isloading =true;
        let _temps=[];
        this.routes.forEach((values) =>{
          values.children.forEach((child) =>{
            if(this.selectedPermission.find(value=>child.id==value)>-1)
              _temps.push(child.permission_id);
          });
        });

        const body = {
          'description': this.role.description,
          'name': this.role.name,
          "permissions":_temps
        };

        let response;
        if(this.isUpate==false)
          response= await PushServicesSocket.GetPostData("/authgroup/create",body);
       else
          response= await PushServicesSocket.PutUpdateData("/authgroup/grant/"+this.contentItem.id,body);
        if(response.status)
        {

          this.msgSucess= this.msgSucess+"  "+  this.role.name;
          this.successDialog = true;
          this.isloading = false;

          setTimeout(() => {
                this.close();
            this.$root.$emit('updateView');
              },3000);

        }else{
          this.msgError = response.error;
          this.isShow = true;
          this.isloading =false;

        }


      } catch (error) {
        this.isShow = true;
        this.isloading =false;
      }
    },


  },
  created() {
    if(this.isUpate)
      this.initializeUpdate();
  }

}
</script>

<style scoped>


</style>
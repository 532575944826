<template>
  <v-card class="card" elevation="0">

    <Loading v-if="isloading"/>
    <v-data-table
        v-else
      :headers="headers"
      :items="contents"
      :search="search"

      @click:row="handleClick">
      class="elevation-1 table">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="بحث"
                single-line
                hide-details
            ></v-text-field
            ></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              persistent
              max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ma-2"
                     v-if="$store.getters.hasCreatePermission('account')"
                     outlined
                     color="indigo" v-bind="attrs" v-on="on"
                    @click="qrDialog = false"
              >
                اضافة {{buttonName}}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
               هل تريد اضافة مستخدم جديد؟
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                  الغاء
                </v-btn>

                <v-btn

                    color="green darken-1"
                    @click="openDialogNew()"
                    text>
                  التالي

                </v-btn>

              </v-card-actions>
            </v-card>
          </v-dialog>

          <createAccount  v-if="isDialog" :is-dialog.sync="isDialog" :is-update="false"/>
          <Accountview  v-if="qrDialog"  :qr-dialog.sync="qrDialog" :itemSelected="itemSelected" :headers="headers" :item-token="selectValue"/>
        </v-toolbar>
      </template>

      <template v-slot:item.is_active="{ item }">
        <v-chip
            :color="getColor(item.is_active)"
            dark
        >
          {{ item.is_active?"فعال":("متوقف") }}
        </v-chip>
      </template>
      <template v-slot:item.is_superuser="{ item }">
        {{ item.is_superuser?"super":"user" }}
      </template>

    </v-data-table>

  </v-card>
</template>
<script>
import Accountview from "@/components/dialogs/Accountview";
import createAccount from "@/components/createAccount";
import PushServicesSocket from "@/services/PushServicesSocket";
import Loading from "@/components/Loading";
export default {
  components:{
    Accountview,
    createAccount,
    Loading,
  },
  props: ['buttonName','headers',"contents"],

  data: () => ({

    groups:[],
    isloading: false,
    search: "",
    qrDialog: false,
    selectValue:"",
    select: "",
    isDialog:false,
    itemSelected:null,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,

  }),

  methods: {
   async setSelected(value){
      await this.getAll(value);
      this.selectValue= value;
    },
    openDialogNew(){
      this.dialog =false;
      this.isDialog= true;
    },

    async handleClick(value){

      this.itemSelected = value;


      this.qrDialog = true;

    },
    getColor(value){

      if (value==true||value=="connected") return 'green'
      else if (value=="disconnected") return 'orange'
      else return 'red'
    },


  },

};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: rgba(202, 202, 202, 0.986);
}


</style>

<template>
  <v-card class="card" elevation="0">

    <Loading v-if="isloading"/>
    <v-data-table
        v-else
      :headers="headers"
      :items="contents"
      :search="search"
      @click:row="handleClick">
      class="elevation-1 table">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="بحث"
                single-line
                hide-details
            ></v-text-field
            ></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              persistent
              max-width="320"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ma-2"
                     v-if="$store.getters.hasCreatePermission('authgroup')"
                     outlined
                     color="indigo" v-bind="attrs" v-on="on"
                    @click="qrDialog = false"
              >
                اضافة {{buttonName}}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
               هل تريد اضافة مجموعة صلاحيات جديد؟
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                  الغاء
                </v-btn>

                <v-btn

                    color="green darken-1"
                    @click="openDialogNew()"
                    text>
                  التالي

                </v-btn>

              </v-card-actions>
            </v-card>
          </v-dialog>

          <createAccount  v-if="isDialog" :content-item="itemSelected" :routes="routes" :is-dialog.sync="isDialog" :is-upate="isUpdate"/>
        </v-toolbar>
      </template>

      <template v-slot:item.edit="{ item }"  >
        <v-row style="width: 100%;"  >


          <v-btn
              class="mx-3"
              dark
              color="red"
              @click="deleteItem(item)"
          >
            حذف
          </v-btn>



          <v-btn
              class="mx-3"
              dark
              color="blue"
              @click="openDialogUpdate(item)"
          >
           تعديل
          </v-btn>


        </v-row>

      </template>

    </v-data-table>
    <DeleteItemDialog :url-name="'/authgroup/delete'" :delete-controller.sync="deleteController" :item-delete="itemSelected" :token="$store.state.token"/>

  </v-card>
</template>
<script>

import createAccount from "@/components/createGroupAuth";
import Loading from "@/components/Loading";
import PushServicesSocket from "@/services/PushServicesSocket";
import store from "@/store";
import permissionManager, {hasCreatePermission} from "@/services/PermissionsManagement";
import DeleteItemDialog from "@/components/dialogs/DeleteItemDialog";
export default {
  components:{
    DeleteItemDialog,
    createAccount,
    Loading,
  },
  props: ['buttonName','headers',"contents"],

  data: () => ({

    groups:[],
    isloading: false,
    search: "",
    deleteController:false,
    qrDialog: false,
    selectValue:"",
    isUpdate:false,
    visible:false,
    select: "",
    isDialog:false,
    itemSelected:null,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    routes:[],

  }),
  async created() {
     this.getPermissionTree();

  },
  methods: {
   async setSelected(value){

      this.selectValue= value;
    },
    async getPermissionTree(){
      let _temps = await PushServicesSocket.GetData("/permission/getall");
      if(_temps.status)
      {
        _temps = _temps.data;
      _temps.forEach((values) =>{
       let container= this.routes.find((value) => {
          return value.container==values.container;
        });

       if(container)
         return container.children.push(values);
        this.routes.push({container:values.container,name:values.label,children:[values]});
      });

      let _count=0;
        this.routes.forEach((values) =>{
          _count=_count+1;
          values.id=_count;
          values.children.forEach((child) =>{
            _count=_count+1;
            child.id=_count;
          });
        });

      }

    },
    async deleteItem(item) {
     this.itemSelected = item;
      this.deleteController =true;
    },
    openDialogUpdate(value){
      this.itemSelected = value;
      this.isUpdate = true;
      this.isDialog= true;
    },
    openDialogNew(){
      this.isUpdate = false;
      this.dialog =false;
      this.isDialog= true;
    },

    async handleClick(value){

      this.itemSelected = value;
      this.qrDialog = true;
    },
    getColor(value){

      if (value==true||value=="connected") return 'green'
      else if (value=="disconnected") return 'orange'
      else return 'red'
    },


  },



};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: rgba(202, 202, 202, 0.986);
}


</style>

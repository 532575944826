<template>
  <div>
    <Loading v-if="isloading" dialog="isloading" title="جاري انشاء مستخدم جديد"/>
      <v-dialog
          width="70%"
          v-model="isDialog">
        <v-card elevation="0">
          <v-card-title>
            <span class="text-h5">   {{isUpdate?`  ${content.name} `:'انشاء مستخدم '}}</span>
          </v-card-title>
          <v-card-text>
            <v-container >
              <v-row >
                <v-col>
                  <v-card ref="form" elevation="0">
                    <v-card-text>
                      <v-form v-model="isFormValid" no-gutters>


                        <v-text-field

                            ref="inputRef"
                            outlined
                            dense
                            :rules="[() => !!full_name || 'يجب ادخال الاسم الكامل']"
                            filled
                            color="rgb(200, 200, 200)"
                            type="name"
                            label="الاسم الكامل"
                            v-model="full_name"
                            value=""
                            class="input-group--focused"
                        ></v-text-field>


                        <v-row    >
                          <v-col cols="12" md="6" sm="8" xs="12" no-gutters>
                            <v-text-field

                                ref="inputRef"
                                outlined
                                dense
                                :rules="[() => !!email || 'يجب ادخال البريد الاكتروني']"
                                filled
                                color="rgb(200, 200, 200)"
                                type="email"
                                label="البريد الاكتروني"
                                v-model="email"
                                placeholder="info@example.com"
                                value=""
                                class="input-group--focused"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6" sm="12" xs="12" no-gutters>
                            <v-text-field

                                ref="inputRef"
                                outlined
                                dense
                                :rules="[() => !!number || 'يجب ادخال رقم الهاتف']"
                                filled
                                color="rgb(200, 200, 200)"
                                type="number"
                                label="رقم الهاتف"
                                v-model="number"
                                value=""
                                class="input-group--focused"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="pa-0" >
                          <v-col cols="12" md="6" sm="8" xs="12" no-gutters>
                            <v-text-field

                                ref="inputRef"
                                outlined
                                dense
                                :rules="[() => !!userid || 'يجب ادخال المستخدم']"
                                filled
                                color="rgb(200, 200, 200)"
                                type="name"
                                label="المستخدم"
                                v-model="userid"
                                value=""
                                class="input-group--focused"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" sm="12" xs="12">
                            <v-text-field

                                ref="inputRef"
                                :append-icon="isPassShow ? 'mdi-eye' : 'mdi-eye-off'"
                                outlined
                                dense
                                :rules="[() => !!password || 'يجب ادخال كلمة المرور']"
                                filled
                                color="rgb(200, 200, 200)"
                                :type="isPassShow ? 'text' : 'password'"
                                label="كلمة المرور"
                                v-model="password"
                                @click:append="isPassShow = !isPassShow"
                                value=""
                                class="input-group--focused"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-select
                            v-if="!(this.switchItems.find(value => value==='is_superuser')=='is_superuser')&&$store.getters.hasChangePermission('authgroup')"

                            ref="inputRef"
                            no-data-text="لا توجد مجموعات صلاحيات"
                            outlined
                            label="اختار مجموعة الصلاحيات"
                            solo
                            v-model="groupAuthSelected"
                            :items="groupAuthItems.data"
                            clearable
                            chips
                            :item-text="item => item.name"
                            item-value="id"
                            multiple
                        >

                          <template v-slot:prepend-item>
                            <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="chooseGroupAll"
                            >
                              <v-list-item-action>
                                <v-icon :color="isChooseGroupSome > 0 ? 'indigo darken-4' : ''">
                                  {{ iconGroup }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  اختيار الكل
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>


                        </v-select>
                        <v-select
                            v-if="!(this.switchItems.find(value => value==='is_superuser')=='is_superuser')&&$store.getters.hasCreatePermission('permission')"
                            ref="inputRef"
                            no-data-text="لا توجد صلاحيات"
                            outlined
                            label="اختار الصلاحيات"
                            solo
                            v-model="permissionsSelected"

                            :items="permissionsItems.data"
                            clearable
                            chips
                            :item-text="item => item.name +' '+item.label"
                            item-value="permission_id"
                            multiple
                        >

                          <template v-slot:prepend-item>
                            <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="chooseAll"
                            >
                              <v-list-item-action>
                                <v-icon :color="isChooseSome > 0 ? 'indigo darken-4' : ''">
                                  {{ icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  اختيار الكل
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>


                        </v-select>
                        <v-row class="pa-0" >
                          <v-col cols="12" md="6" sm="8" xs="12" no-gutters>

                            <v-switch
                                v-model="switchItems"
                                label="تفعيل المستخدم"
                                color="success"
                                value="is_active"
                                hide-details
                            ></v-switch>
                          </v-col>
                          <v-col cols="12" md="6" sm="12" xs="12">
                            <v-switch
                                v-if="$store.getters.hasCreatePermission('permission')"
                                v-model="switchItems"
                                label=" صلاحيات super user"
                                color="success"
                                value="is_superuser"
                                hide-details
                            ></v-switch>

                          </v-col>
                        </v-row>

                      </v-form>

                    </v-card-text>
                  </v-card>
                  <v-row>

                    <v-btn class="ma-2"
                           width="20%"
                           outlined
                           color="paink"

                           @click="close"
                    >
                      الغاء
                    </v-btn>

                    <v-btn class="ma-2"
                           width="20%"
                           outlined
                           color="green"
                           :disabled="!isFormValid"
                           @click="createAccount()"
                    >
                      {{isUpdate?'تحديث':'انشاء'}}
                    </v-btn>

                  </v-row>
                </v-col>
              </v-row>
            </v-container>

          </v-card-text>


        </v-card>
        <v-alert
            class="mt-5"
            left
            border="left"
            color="red"
            dismissible
            elevation="9"
            type="error"
            v-model="isShow"
        >{{msgError}}</v-alert>
        <SuccessDialog :title="msgSucess"  v-if="successDialog" :success-dialog.sync="successDialog" />
      </v-dialog>




  </div>

</template>

<script>

import Loading from '@/components/LoadingDialog.vue';
import PushServicesSocket from '@/services/PushServicesSocket.js';
import SuccessDialog from '@/components/dialogs/SucessSended.vue';
import Base64 from "@/services/Base64";

export default {
  components: {
    Loading,
    SuccessDialog
  },
  props:{
    content:{},
    isUpdate:Boolean,
    deviceToken:String,
    isDialog: {
      default: false
    },
  },

  data () {
    return {
      value: [1],
      itemsRole:this.generateData(),
      isFormValid: false,
      isloading: false,
      full_name:"",
      email:"",
      permissionsSelected:[],
      permissionsItems:[],
      groupAuthSelected:[],
      groupAuthItems:[],
      userid:"",
      isPassShow:false,
      password:"",
      number:"",
      switchItems:[],
      isShow: false,
      successDialog: false,
      msgSucess:this.isUpdate?"تم تحديث المستخدم بنجاح":"تم انشاء المستخدم بنجاح",
      msgError:"فشل ",

    }
  },

  methods: {
    close() {
      this.$emit('update:isDialog', false);
    },
    handleChange(value, direction, movedKeys) {
      console.log(value, direction, movedKeys);
    },
     generateData () {
      const data = [];
      for (let i = 1; i <= 15; i++) {
        data.push({
          key: i,
          label: `Option ${ i }`,
          disabled: i % 4 === 0
        });
      }
      return data;
    },
    chooseAll () {
      if (this.isChooseAll) {
        this.permissionsSelected = [];
      } else {
        this.permissionsSelected = this.permissionsItems.data.slice().map(e=>e.permission_id);
      }
    },
    chooseGroupAll () {
      if (this.isChooseGroupAll) {
        this.groupAuthSelected = [];
      } else {
        this.groupAuthSelected = this.groupAuthItems.data.slice().map(e=>e.id);
      }
    },
    async getPermissions(){
      this.isloading = true;
      this.permissionsItems = await PushServicesSocket.GetData("/permission/getall");

      this.isloading = false;
    },
    async getGroupAuth(){
      this.isloading = true;
      this.groupAuthItems = await PushServicesSocket.GetData("/authgroup/getall");

      this.isloading = false;
    },
    async getMyGroupAuth(userID){
      this.isloading = true;
      this.groupAuthSelected = await PushServicesSocket.GetData("/authgroup/getuser/"+userID);
      if(this.groupAuthSelected.status)
        this.groupAuthSelected =this.groupAuthSelected.data.map(e=>e.id);
      this.isloading = false;
    },async getMyPermissions(userID){
      this.isloading = true;
      this.permissionsSelected = await PushServicesSocket.GetData("/permission/get/"+userID);
      if(this.permissionsSelected.status)
        this.permissionsSelected =this.permissionsSelected.data.map(e=>e.permission_id);
      this.isloading = false;
    },
    async createAccount() {



      try {

        this.isloading =true;
        const body = {

          'name': this.full_name,
          'userid': this.userid,
          'password': this.password,
          'email': this.email,
          'number': this.number,
          'is_active': this.switchItems.find(value => value==="is_active")=='is_active',
          'is_superuser': this.switchItems.find(value => value==="is_superuser")=='is_superuser',
        };

        let response;
        if(!this.isUpdate)
          response= await PushServicesSocket.GetPostData("/account/create",body);
        else
           response = await PushServicesSocket.PutUpdateData("/account/update/"+this.content?.id,body);

           if(response.status)
        {
          this.msgSucess=this.isUpdate?response.message:  (this.msgSucess+"  "+this.full_name);
          this.successDialog = true;
          this.isloading = false;

          if(this.$store.getters.hasChangePermission('authgroup'))
              PushServicesSocket.PutUpdateData("/authgroup/grantuser/"+(this.isUpdate?this.content?.id:response.data.id),{groups:this.groupAuthSelected});
          if(this.$store.getters.hasCreatePermission('permission'))
              PushServicesSocket.PutUpdateData("/permission/grant/"+(this.isUpdate?this.content?.id:response.data.id),{permissions:this.permissionsSelected});

          setTimeout(() => {
                this.close();
            this.$root.$emit('updateView');
              },3000);


        }else{
          this.msgError = response.error;
          this.isShow = true;
          this.isloading =false;

        }


      } catch (error) {
        this.isShow = true;
        this.isloading =false;
      }


    },
   async initializeUpdate(){

     if(this.$store.getters.hasViewPermission('authgroup'))
      await this.getMyGroupAuth(this.content.id);
     if(this.$store.getters.hasViewPermission('permission'))
     await this.getMyPermissions(this.content.id);
      this.full_name = this.content.name;
      this.email = this.content.email;
      this.userid = this.content.userid;
      this.password = Base64.decode(this.content.password);
      this.number = this.content.number;
      if( this.content.is_active===true)
      this.switchItems.push('is_active') ;
      if(this.content.is_superuser===true)
        this.switchItems.push('is_superuser') ;



    }

  },
  computed:{
    isChooseAll () {
      return this.permissionsSelected?.length??0 === this.permissionsItems.data.length
    },
    isChooseSome () {
      return this.permissionsSelected?.length??0 > 0
    },
    icon () {
      if (this.isChooseAll) return 'mdi-close-box'
      if (this.isChooseSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    isChooseGroupAll () {
      return this.groupAuthSelected?.length??0 === this.groupAuthItems.data.length
    },
    isChooseGroupSome () {
      return this.groupAuthItems?.length??0 > 0
    },
    iconGroup () {
      if (this.isChooseGroupAll) return 'mdi-close-box'
      if (this.isChooseGroupSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },

  },
  async created() {
    if(this.$store.getters.hasViewPermission('permission'))
    await this.getPermissions();
    if(this.$store.getters.hasViewPermission('authgroup'))
    await this.getGroupAuth();
    if(this.isUpdate)
      this.initializeUpdate();

  }
}
</script>

<style scoped>


</style>
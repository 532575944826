<template>


  <v-tabs
      color="deep-purple accent-4"
      centered
  >
    <v-tab v-if="$store.getters.hasViewPermission('account')">المستخدمين</v-tab>

    <v-tab v-if="$store.getters.hasViewPermission('authgroup')">مجموعة الصلاحيات</v-tab>


    <v-tab-item>
      <Loading v-if="isloading"/>
      <v-container v-else>
        <v-row>
          <v-card-title>
            المستخدمين
          </v-card-title>
          <v-btn
              icon
              color="blue"
              class="pa-10"
              @click="getAllAccount()"
          >
            <v-icon size="40px">mdi-cached</v-icon>
          </v-btn>

        </v-row>
        <TableView :headers="headers" :contents="accountsItems" buttonName=" مستخدم" />
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <Loading v-if="isloading"/>
      <v-container v-else>
        <v-row>
          <v-card-title>
            مجموعة الصلاحيات
          </v-card-title>
          <v-btn
              icon
              color="blue"
              class="pa-10"
              @click="getAllAccount()"
          >
            <v-icon size="40px">mdi-cached</v-icon>
          </v-btn>

        </v-row>
        <GroupPermissionsTable :headers="headersGroup"  :contents="groupAuthItems" buttonName=" مجموعة الصلاحيات" />
      </v-container>
    </v-tab-item>
  </v-tabs>

</template>

<script>
    import TableView from '../components/AccountTable.vue';
    import GroupPermissionsTable from '../components/GroupPermissionsTable.vue';
    import Loading from '@/components/Loading.vue';
    import PushServicesSocket from "@/services/PushServicesSocket";
    import permissionsManagement from "@/services/PermissionsManagement";

    export default {
        components:{
          TableView,
          Loading,
          GroupPermissionsTable
        },
        data: ()=>({


          accountsItems:[],
          groupAuthItems:[],
          isloading:false,
          headersGroup:[
            {
              text: "الرقم",
              align: "start",
              sortable: false,
              value: "id",
            },
            { text: "اسم مجموعة الصلاحيات", value: "name" },
            { text: "الوصف", value: "description" },
            { text: "العمليات", value: "edit" },
          ],
          headers: [
      {
        text: "الاسم الكامل",
        align: "start",
        sortable: false,
        value: "name",
      },
    { text: "المستخدم", value: "userid" },
    { text: "رقم الهاتف", value: "number" },

    { text: "البريد الالكتروني", value: "email" },
    { text: "نوع المستخدم", value: "is_superuser" },
      { text: "الحالة", value: "is_active" },


   
    ],

        }),
      created() {
        if(this.$store.getters.hasViewPermission('account'))
        this.getAllAccount();
        if(this.$store.getters.hasViewPermission('authgroup'))
        this.getAllGroupAuth();
      },

      methods: {
          async getAllAccount(){
            this.isloading = true;
            this. accountsItems = await PushServicesSocket.GetData("/account/getall");
            if(this.accountsItems?.status)
            {
              this.accountsItems = this.accountsItems.accounts;

            }


            this.isloading = false;
          },
        async getAllGroupAuth(){
          this.isloading = true;
          this. groupAuthItems = await PushServicesSocket.GetData("/authgroup/getall");
          if(this.groupAuthItems?.status)
          {
            this.groupAuthItems = this.groupAuthItems.data;

          }


          this.isloading = false;
        },
        },
     async mounted() {

        this.$root.$on('updateView', () => {
          this.getAllAccount();
          this.getAllGroupAuth();
        });
      }
    }
</script>

<style scoped>

</style>